var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"BaseColor FormField FormField--BaseColor",class:_vm.elmClasses},[_c('div',{staticClass:"BaseColor__inner FormField__inner"},[_c('div',{staticClass:"BaseColor__bg FormField__bg"}),_c('div',{staticClass:"BaseColor__swatchWrapper pattern--checkerboard"},[_c('div',{staticClass:"BaseColor__swatchPresentation",style:({
					backgroundColor : _vm.solidColorValue
				})}),_c('div',{staticClass:"BaseColor__swatchPresentation",style:({
					backgroundColor : _vm.model
				})}),_c('input',{ref:"swatchInputElm",staticClass:"BaseColor__swatchInput",attrs:{"type":"color"},domProps:{"value":_vm.hexColorValue},on:{"input":_vm.onChangeColorInput}})]),_c('div',{staticClass:"BaseColor__separator FormField__border"}),_c('input',{ref:"solidColorValueElm",staticClass:"BaseColor__input BaseColor__input--solidColorValue FormField__input",attrs:{"type":"text"},domProps:{"value":_vm.solidColorValue},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onChangeSolidColorValue.apply(null, arguments)},"blur":_vm.onChangeSolidColorValue}}),_c('div',{staticClass:"BaseColor__separator FormField__border"}),_c('input',{directives:[{name:"stepper",rawName:"v-stepper",value:({
				unit : '%',
				max : 100,
				min : 0,
				step : 1,
				callback : _vm.onChangeAlphaValue
			}),expression:"{\n\t\t\t\tunit : '%',\n\t\t\t\tmax : 100,\n\t\t\t\tmin : 0,\n\t\t\t\tstep : 1,\n\t\t\t\tcallback : onChangeAlphaValue\n\t\t\t}"}],ref:"alphaValueElm",staticClass:"BaseColor__input BaseColor__input--alphaValue FormField__input",attrs:{"type":"text"},domProps:{"value":( _vm.alphaValue * 100 ) + '%'},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.onChangeAlphaValue.apply(null, arguments)},"blur":_vm.onChangeAlphaValue}}),_c('div',{staticClass:"BaseColor__border FormField__border"})]),(_vm.debug)?_c('div',{staticClass:"BaseColor__debug"},[_c('pre',{attrs:{"name":"value"}},[_vm._v(_vm._s(_vm.value))]),_c('pre',{attrs:{"name":"model"}},[_vm._v(_vm._s(_vm.model))]),_c('pre',{attrs:{"name":"solidColorValue"}},[_vm._v(_vm._s(_vm.solidColorValue))]),_c('pre',{attrs:{"name":"alphaValue"}},[_vm._v(_vm._s(_vm.alphaValue))])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }